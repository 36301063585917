.products {
  width: 100%;
  background-color: var(--color-gray-surface);
  display: flex;
  flex-direction: column;

  &__header {
    padding: 2vw 13vw;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 3vw;
      font-weight: 400;
      color: var(--color-dark);
      margin-bottom: 1rem;
    }
  }

  &__main {
    min-height: 80vh;
    padding: 2vw 13vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 6rem;

    .productCard {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-dark);
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      background-color: var(--color-gray-surface);
      margin-bottom: 4vw;

      &__image {
        position: relative;
        width: 100%;
        height: 16vw;
        align-items: flex-start;
        margin-bottom: 1.2vw;

        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }
      }

      &__title {
        width: 90%;
        display: flex;
        justify-content: center;
        font-size: 1.45vw;
        color: var(--color-primary);
        font-weight: 400;
        margin-bottom: 1.2vw;
      }

      &__btn {
        width: 50%;
        padding: 6px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 4px;
        font-size: 1.1vw;
        font-weight: 300;
        color: var(--color-dark);
        background-color: var(--color-secondary);
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        margin-bottom: 1.2vw;
      }
      &__btn:hover {
        background-color: var(--color-secondary-hover);
      }
    }

    .productCard:hover {
      box-shadow: none;
      background-color: var(--color-white);
    }
  }
}

@media screen and (max-width: 576px) {
  .products {
    width: 100%;

    &__header {
      padding: 4vw 6vw;

      h1 {
        font-size: 6vw;
      }
    }

    &__main {
      min-height: 80vh;
      padding: 4vw 6vw;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      margin-bottom: 6rem;

      .productCard {
        width: 100%;
        margin-bottom: 2.5rem;

        &__image {
          width: 100%;
          height: 70vw;
          margin-bottom: 1rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &__title {
          width: 90%;
          display: flex;
          font-size: 6vw;
          margin-bottom: 1rem;
        }

        &__btn {
          width: 60%;
          font-size: 3.8vw;
          margin-bottom: 1rem;
        }
      }
    }
  }
}
