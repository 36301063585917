.product__header {
  width: 50%;
  padding-top: 3rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-image {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 3vw;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 1.5vw;
      font-weight: 400;
      color: var(--color-dark);
      margin-bottom: 1vw;
    }

    &-subtitle {
      font-size: 1.1vw;
      font-weight: 300;
      color: var(--color-gray);
      text-align: justify;
      line-height: 1.5;
    }
  }
}

@media screen and (max-width: 576px) {
  .product__header {
    width: 90%;
    padding-top: 2.5rem;

    &-image {
      width: 75%;
      height: auto;
      margin-bottom: 2rem;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-content {
      &-title {
        font-size: 6vw;
        margin-bottom: 1rem;
      }

      &-subtitle {
        font-size: 3.8vw;
      }
    }
  }
}
