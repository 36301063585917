.hero {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  &__bg {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.38);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: top right;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    padding-top: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;

    &-left {
      width: 46%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-btn {
        width: 90%;
        display: flex;
        padding-top: 1vw;

        button {
          padding: 10px 30px;
          font-size: 1.2vw;
          font-weight: 400;
          background-color: transparent;
          border: 3px solid var(--color-primaryLight);
          color: var(--color-primaryLight);
          border-radius: 30px;
          cursor: pointer;
          transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        }
        button:hover {
          background-color: var(--color-primaryLight);
          color: var(--color-gray-surface);
        }
      }

      &-brand {
        width: 90%;
        display: flex;
        align-items: center;
        justify-self: flex-start;
        margin-bottom: 1.6vw;

        .line {
          width: 3vw;
          border-bottom: 8px solid var(--color-primaryLight);
          margin-right: 0.8vw;
        }

        h6 {
          font-size: 3vw;
          font-weight: 500;
          color: var(--color-primaryLight);
        }
      }

      h1 {
        width: 90%;
        font-weight: 500;
        font-size: 2.4vw;
        color: var(--color-gray-surface);
        margin-bottom: 1.6vw;
      }

      p {
        width: 90%;
        padding-right: 10%;
        font-weight: 300;
        font-size: 1.2vw;
        color: var(--color-gray-surface);
        margin-bottom: 1.6vw;

        strong {
          font-size: 1.1vw;
          color: var(--color-gray-surface);
          font-weight: 300;
        }
      }
    }

    &-right {
      width: 46%;
      display: flex;
      justify-content: center;

      &-anim {
        width: 30vw;
        height: 30vw;
        overflow: hidden;

        div {
          width: 30vw;
          height: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .hero {
    &__bg {
      display: flex;
      align-items: flex-start;
      background-size: 100% auto;
      background-attachment: scroll;
    }
  }
}

@media screen and (max-width: 640px) {
  .hero {
    min-height: 100vh;
    overflow-x: hidden;

    &__bg {
      display: flex;
      align-items: flex-start;
      background-color: rgba(0, 0, 0, 0.38);
      background-blend-mode: multiply;
      background-size: auto 100%;
      background-position: top right;
      background-attachment: scroll;
      width: 100%;
      height: 100vh;
      z-index: -1;
    }

    &__content {
      padding-top: 1vw;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      overflow-x: hidden;

      &-left {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-btn {
          width: 100%;
          padding-top: 0.4rem;

          button {
            padding: 6px 26px;
            font-size: 3.6vw;
          }
        }

        &-brand {
          width: 100%;
          align-items: center;
          justify-self: flex-start;
          margin-bottom: 1rem;

          .line {
            width: 5vw;
            border-bottom: 5px solid var(--color-primaryLight);
            margin-right: 2.4vw;
          }

          h6 {
            font-size: 5.4vw;
          }
        }

        h1 {
          width: 100%;
          font-size: 4.5vw;
          margin-bottom: 1rem;
        }

        p {
          width: 100%;
          padding-right: 0;
          font-size: 3.4vw;
          margin-bottom: 1rem;
        }
      }

      &-right {
        margin-top: 5rem;
        width: 90%;
        display: flex;
        justify-content: center;

        padding-bottom: 3rem;

        &-anim {
          width: 50vw;
          height: 50vw;

          div {
            width: 50vw;
            height: auto;
          }
        }
      }
    }
  }
}
