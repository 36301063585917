.scitech {
  width: 100%;
  background-color: var(--color-gray-surface);
  display: flex;
  flex-direction: column;

  &__main {
    margin: 2rem auto 6rem;
    min-height: 80vh;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    &-product {
      width: 49%;
      height: auto;
      margin-bottom: 2.2%;
    }
  }
}

@media screen and (max-width: 576px) {
  .scitech {
    &__main {
      margin: 3rem auto 6rem;
      width: 90%;
      flex-direction: column;
      flex-wrap: nowrap;

      &-product {
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
      }
    }
  }
}
