.primeblade {
  width: 100%;
  background-color: var(--color-gray-surface);
  display: flex;
  flex-direction: column;

  .player_wrapper {
    width: auto;
    height: 68vh;
    position: relative;
    left: 0;
    top: 0;
    object-position: center;
    object-fit: contain;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: -1rem;

    .react-player {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);

      iframe {
        border-radius: 12px !important;
      }
    }
  }

  &__main {
    min-height: 80vh;
    margin: 4rem auto 6rem;
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-product {
      width: 32%;
      height: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 2%;

      h6 {
        text-align: center;
        font-size: 1vw;
        font-weight: 300;
        color: var(--color-dark);
        margin-bottom: 1.2vw;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-product:hover {
      img {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transform: scale(1.02);
        -webkit-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -ms-transform: scale(1.02);
        -o-transform: scale(1.02);
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .primeblade {
    .player_wrapper {
      width: auto;
      height: 18rem;
      margin-bottom: -2rem;
    }

    &__main {
      margin: 3.5rem auto 6rem;
      width: 90%;
      flex-direction: column;
      flex-wrap: nowrap;

      &-product {
        width: 100%;
        height: auto;
        display: flex;
        margin-bottom: 2rem;

        h6 {
          text-align: center;
          font-size: 5vw;
          font-weight: 300;
          color: var(--color-dark);
          margin-bottom: 0.6rem;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
