.ochem {
  width: 100%;
  background-color: var(--color-gray-surface);
  display: flex;
  flex-direction: column;

  &__main {
    margin: 2vw auto 6rem;
    min-height: 35vh;
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    &-product-1 {
      width: 32%;
      height: auto;
    }

    &-product-2 {
      width: 32%;
      height: auto;
    }

    &-product-3 {
      width: 32%;
      height: auto;
    }
  }
}

@media screen and (max-width: 576px) {
  .ochem {
    &__main {
      margin: 2rem auto 6rem;
      width: 90%;
      flex-direction: column;
      flex-wrap: nowrap;

      &-product-1 {
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
      }

      &-product-2 {
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
      }

      &-product-3 {
        width: 100%;
        height: auto;
        margin-bottom: 1.5rem;
      }
    }
  }
}
