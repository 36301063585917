.aboutUs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray-surface);
  margin-bottom: 15rem;
  height: auto;

  &__image {
    width: 50%;
    display: flex;
    justify-content: center;
    background-color: #f8fcff;
    align-items: flex-start;

    img {
      width: 80%;
      height: auto;
    }
  }

  &__content {
    padding: 0 2vw;
    width: 50%;
    display: flex;
    flex-direction: column;

    h4 {
      color: var(--color-dark);
      font-size: 3vw;
      font-weight: 600;
      font-family: var(--font-serif);
      letter-spacing: 1.5px;
      margin-bottom: 2vw;
    }

    p {
      width: 80%;
      color: var(--color-dark);
      font-size: 1.2vw;
      font-weight: 300;
    }
  }

  &__footer {
    width: 100%;
    display: flex;

    &-item {
      padding: 3vw 4vw;
      width: 34%;
      display: flex;
      flex-direction: column;

      .icon-eco {
        font-size: 8vw;
        color: var(--color-secondary);
        margin: 0 auto;
      }
      .icon-manufacture {
        font-size: 8vw;
        color: var(--color-secondary);
        margin: 0 auto;
      }
      .icon-box {
        padding: 0.3vw;
        font-size: 8vw;
        color: var(--color-secondary);
        margin: 0 auto;
      }

      span {
        display: flex;
        flex-direction: column;
        margin-top: 1vw;

        h4 {
          color: var(--color-gray-surface);
          font-size: 2.1vw;
          font-weight: 600;
          font-family: var(--font-serif);
          margin-bottom: 1vw;
        }

        p {
          color: var(--color-gray-surface);
          font-size: 1.2vw;
          font-weight: 300;
        }
      }
    }

    .footer-item-1 {
      background-color: var(--color-primaryDark-hover);
    }
    .footer-item-2 {
      background-color: var(--color-primaryDark);
    }
    .footer-item-3 {
      background-color: var(--color-primaryDark-surface);
    }
  }
}

@media screen and (max-width: 992px) {
  .aboutUs {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .aboutUs {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

    &__image {
      width: 100%;

      img {
        width: 80%;
        height: auto;
      }
    }

    &__content {
      padding: 8vw 2vw;
      width: 85%;
      display: flex;
      flex-direction: column;

      h4 {
        text-align: center;
        font-size: 6vw;
        margin-bottom: 3vw;
      }

      p {
        width: 100%;
        font-size: 3.6vw;
      }
    }

    &__footer {
      width: 100%;
      flex-direction: column;

      &-item {
        padding: 6vw 4vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon-eco {
          font-size: 16vw;
          color: var(--color-secondary);
          margin: 0 auto;
        }
        .icon-manufacture {
          font-size: 16vw;
          color: var(--color-secondary);
          margin: 0 auto;
        }
        .icon-box {
          font-size: 14.5vw;
          color: var(--color-secondary);
          margin: 0 auto;
        }

        span {
          width: 70%;
          display: flex;
          flex-direction: column;
          margin-top: 1vw;

          h4 {
            color: var(--color-gray-surface);
            font-size: 5vw;
            font-weight: 600;
            font-family: var(--font-serif);
            margin-bottom: 2vw;
          }

          p {
            color: var(--color-gray-surface);
            font-size: 3.6vw;
            font-weight: 300;
          }
        }
      }
    }
  }
}
