.layout {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  &__container {
    padding-top: 12vh;
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .layout {
    &__container {
      padding-top: 10vh;
    }
  }
}
