.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__address {
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: var(--color-gray-surface);
    padding: 2vw 20%;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1vw;

      h5 {
        font-size: 1.34vw;
        font-weight: 500;
        color: var(--color-primary);
        letter-spacing: 2px;
        padding-bottom: 1vw;
      }

      address {
        text-align: center;
        width: 95%;
        font-size: 1vw;
        font-weight: 300;
        color: var(--color-dark);
        letter-spacing: 1.2px;
      }
    }
  }

  .footer__copyright {
    width: 100%;
    align-self: center;
    padding: 2vw 0;
    text-align: center;
    font-size: 1vw;
    font-weight: 300;
    color: var(--color-gray-surface);
    background-color: var(--color-primaryDark);
  }
}

@media screen and (max-width: 640px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &__address {
      width: 100%;
      flex-direction: column;
      padding: 2.5rem 1rem;
      background-color: var(--color-white);

      &-item {
        width: 100%;
        padding-bottom: 2.5rem;

        h5 {
          font-size: 5vw;
          padding-bottom: 1rem;
        }
        address {
          text-align: center;
          width: 95%;
          font-size: 3.8vw;
        }
      }
    }

    .footer__copyright {
      padding: 6vw 1rem;
      font-size: 3.8vw;
    }
  }
}
