.vision-n-mission {
  width: 100%;
  background-color: var(--color-gray-surface);
  margin-bottom: 15rem;

  .vision {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-surface);

    &__image {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background-color: var(--color-primaryLight);
      border-bottom-left-radius: 40%;

      img {
        margin-left: 1vw;
        width: 100%;
        height: auto;
        border-bottom-left-radius: 40%;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      img:hover {
        margin: 0;
      }
    }

    &__content {
      padding: 0 2vw;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        width: 80%;
        display: flex;
        align-items: center;
        margin-bottom: 2vw;

        span {
          border-radius: 10px;
          width: 10%;
          border-top: 8px solid var(--color-primaryLight);
          margin-right: 2%;
        }

        h4 {
          color: var(--color-dark);
          font-size: 3vw;
          font-weight: 600;
          font-family: var(--font-serif);
          letter-spacing: 2px;
          padding-left: 0.5vw;
        }
      }

      p {
        width: 80%;
        color: var(--color-dark);
        font-size: 1.2vw;
        font-weight: 300;
        line-height: 1.8;
      }
    }
  }

  .mission {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-surface);

    &__image {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background-color: var(--color-primaryLight);
      border-top-right-radius: 40%;

      img {
        margin-right: 1vw;
        width: 100%;
        height: auto;
        border-top-right-radius: 40%;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      img:hover {
        margin-right: 0;
      }
    }

    &__content {
      padding: 0 2vw;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      div {
        width: 80%;
        display: flex;
        align-items: center;
        margin-bottom: 2vw;

        span {
          border-radius: 10px;
          width: 10%;
          border-top: 8px solid var(--color-primaryLight);
          margin-right: 2%;
        }

        h4 {
          color: var(--color-dark);
          font-size: 3vw;
          font-weight: 600;
          font-family: var(--font-serif);
          letter-spacing: 2px;
          padding-left: 0.5vw;
        }
      }

      p {
        width: 80%;
        color: var(--color-dark);
        font-size: 1.2vw;
        font-weight: 300;
        line-height: 1.8;
      }

      &-list {
        width: 80%;
        padding-left: 2rem;
        margin-bottom: 0.4vw;

        li {
          font-size: 1.2vw;
          font-weight: 300;
          line-height: 1.8;
          color: var(--color-dark);
          list-style-type: disc;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .vision-n-mission {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .vision-n-mission {
    width: 100%;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .vision {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-gray-surface);
      margin-bottom: 2rem;

      flex-direction: column;
      margin: 1rem auto;
      width: 90%;
      border-radius: 6px 6px 6px 40%;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      &__image {
        width: 100%;
        border-bottom-left-radius: 40%;
        border-bottom-right-radius: 6px;

        img {
          margin-left: 0;
          border-bottom-left-radius: 40%;
          border-bottom-right-radius: 6px;

          margin-bottom: 0.8rem;
        }
        img:hover {
          margin-bottom: 0.8rem;
        }
      }

      &__content {
        padding: 2rem 0;
        width: 90%;

        div {
          width: 90%;
          margin-bottom: 1rem;

          span {
            border-radius: 10px;
            width: 11%;
            margin-right: 3%;
          }

          h4 {
            font-size: 6vw;
            padding-left: 0.5vw;
          }
        }

        p {
          width: 90%;
          font-size: 3.8vw;
        }
      }
    }

    .mission {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--color-gray-surface);

      flex-direction: column-reverse;
      margin: 1rem auto;
      width: 90%;
      border-radius: 6px 6px 40% 6px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      &__image {
        width: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 40%;
        border-bottom-left-radius: 6px;

        img {
          margin-right: 0;
          border-top-right-radius: 0;

          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 40%;
          margin-bottom: 0.8rem;
        }
      }

      &__content {
        padding: 2rem 0;
        width: 90%;

        div {
          width: 90%;
          margin-bottom: 1rem;

          span {
            border-radius: 10px;
            width: 11%;
            margin-right: 3%;
          }

          h4 {
            font-size: 6vw;
            padding-left: 0.5vw;
          }
        }

        p {
          width: 90%;
          font-size: 3.8vw;
        }

        &-list {
          width: 90%;
          padding-left: 1.4rem;
          margin-bottom: 0.8rem;

          li {
            font-size: 3.8vw;
          }
        }
      }
    }
  }
}
