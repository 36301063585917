.customModal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  background: rgba($color: #000000, $alpha: 0.3);
  overflow: hidden !important;
  justify-content: center;
  align-items: center;

  hr {
    border-color: rgb(153, 153, 177);
  }

  &-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
