.navbar-light {
  background-color: var(--color-gray-surface);

  .navbar__link {
    &-item {
      color: var(--color-dark);
    }
  }

  .navbar__menu {
    color: var(--color-dark);
  }
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12%;
  z-index: 2;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &__brand {
    width: 100%;
    height: 12vh;

    img {
      padding: 2vh 0;
      background-color: var(--color-gray-surface);
      width: auto;
      height: 100%;
    }
  }

  &__link {
    display: flex;

    &-item {
      white-space: nowrap;
      font-weight: 300;
      font-size: 1.1vw;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    a {
      margin-left: 3vw;
    }
  }

  &__menu {
    display: none;
    font-size: 2rem;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.offcanvas {
  display: none;
}

@media screen and (max-width: 576px) {
  .navbar {
    height: 10vh;
    padding: 0 12%;

    &__brand {
      height: 10vh;

      img {
        padding: 2vh 0;
        width: auto;
        height: 100%;
      }
    }

    &__link {
      display: none;

      &-item {
        white-space: nowrap;
        font-weight: 300;
        font-size: 1.1vw;
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
        margin-left: 0;
      }
    }

    &__menu {
      display: block;
      font-size: 2rem;
    }
  }

  .show-nav {
    right: 0vw;
  }

  .hidden-nav {
    right: -72vw;
  }

  .offcanvas {
    position: fixed;
    top: 0;
    width: 70vw;
    height: 110vh;
    background-color: var(--color-success);
    color: var(--color-gray-dark);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.7s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    display: flex;
    flex-direction: column;

    &__close {
      height: 10vh;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      align-self: flex-end;
      padding-right: 8%;

      &-icon {
        color: var(--color-white);
        font-size: 8vw;
      }
    }

    &__nav {
      width: 85%;
      margin: 10vw auto 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-link {
        color: var(--color-white);
        font-size: 4.5vw;
        font-weight: 600;
        border: none;
        text-decoration: none;
        margin-bottom: 2rem;
      }
    }
  }
}
