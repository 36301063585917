.auclean {
  width: 100%;
  background-color: var(--color-gray-surface);
  display: flex;
  flex-direction: column;

  &__main {
    margin: 4vw auto 6rem;
    min-height: 80vh;
    width: 84%;
    border-radius: 8px;
    background-color: #f4f4f4;
    border: 1px solid #cecece;

    hr {
      border-top: 4px solid #cecece;
    }

    .product__card {
      padding: 1vw;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-title {
        width: 100%;
        font-size: 1.4vw;
        color: var(--color-primary);
        font-weight: 400;
        margin-bottom: 2vw;
      }

      &-header {
        width: 28%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1vw;

        img {
          width: 100%;
          height: auto;
          border-radius: 12px;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }

      &-body {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1vw;

        &-paragraph {
          width: 80%;
          font-size: 1vw;
          color: var(--color-dark);
          font-weight: 300;
          line-height: 1.8;
        }

        &-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.4vw;

          h6 {
            width: 53%;
            font-size: 1vw;
            color: var(--color-dark);
            font-weight: 300;
          }

          p {
            width: 50%;
            font-size: 1vw;
            color: var(--color-dark);
            font-weight: 300;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .auclean {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__main {
      width: 90%;
      margin: 2rem auto 6rem;

      hr {
        border-top: 3px solid #cecece;
        width: 90%;
        margin: 0 auto;
      }

      .product__card {
        padding: 4vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;

        &-title {
          width: 100%;
          font-size: 5vw;
          margin: 0 auto 1.5rem;
        }

        &-header {
          width: 100%;
          margin-bottom: 1.5rem;
        }

        &-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          &-paragraph {
            width: 100%;
            font-size: 3.6vw;
          }

          &-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.8rem;

            h6 {
              width: 52%;
              font-size: 3.6vw;
            }

            p {
              width: 50%;
              font-size: 3.6vw;
            }
          }
        }
      }
    }
  }
}
